<template>
	<div class="cropper container">
		<div class="cropper__left" ref="croppaContainer">
			<croppa v-model="croppa"
				ref="croppa"
				:width="1000"
				:height="1000"
				placeholder=""
				:image-border-radius="75"
				:accept="'image/*'"
				:zoom-speed="1.5"
				prevent-white-space
				@draw="onDraw"
				:file-size-limit="1e+7"
				@file-size-exceed="onFileSizeExceed"
				:show-remove-button="false"
				@file-choose="startLoad"
				@loading-end="endLoad"
			>
			</croppa>

			<div class="preloader" v-if="loader">
				<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
				<!-- <div class="loader"></div> -->
			</div>

			<div class="rotateBlock" v-if="croppa.img">
				<a @click="rotateLeft"><span class="icon-rotate-1"></span></a>
				<a @click="rotateRight"><span class="icon-rotate-2"></span></a>
			</div>

			<div class="croppaInputes inputes" ref="croppaInputes">
				<div class="uploadButton" :class="{noCenter: activeImage}" v-if="!croppa.img && !loader">
					<a class="uploadButton--button" @click="uploadImageClick()">
						<span class="icon-plus"></span>
					</a>
					<a class="uploadButton--text" @click="uploadImageClick()">Загрузите фото</a>
				</div>

				<div class="fakeBlock1 hideMobile" :style="{height: Math.max(this.departmentSplit.length * 20 + 44, 64) + 'px'}"></div>
				<div class="fakeBlock2 hideMobile" :style="{height: 121 + ((this.positionSplit.length - 1) * 20) + 'px'}"></div>

				<textarea name="department" class="croppaInputes--department hideMobile" maxlength="50" placeholder="Департамент или отдел" :class="{noempty: department != ''}" :style="{height: Math.max(this.departmentSplit.length * 20 + 16, 36) + 'px'}" v-model="department"></textarea>
				<input type="text" v-model="name" :class="{noempty: name != ''}" class="croppaInputes--name hideMobile" maxlength="26" placeholder="Имя" :style="{top: Math.min(700, 710 - (this.positionSplit.length * 10)) + 'px'}">
				<input type="text" v-model="surname" :class="{noempty: surname != ''}" class="croppaInputes--surname hideMobile" maxlength="26" placeholder="Фамилия">
				<textarea name="position" class="croppaInputes--position hideMobile" maxlength="50" placeholder="Должность" :class="{noempty: position != ''}" :style="{height: Math.max(this.positionSplit.length * 20 + 16, 36) + 'px', top: Math.min(700, 720 - (this.positionSplit.length * 20)) + 'px'}" v-model="position"></textarea>
				<input type="text" v-model="email" :class="{noempty: email != ''}" class="croppaInputes--email hideMobile" maxlength="26" placeholder="Email">
			</div>
		</div>
		<div class="cropper__right">
			<div class="inputesContainer showMobile">
				<p class="inputesContainer--title">Заполните форму</p>
				<div class="inputes">
					<input type="text" v-model="name" :class="{noempty: name != ''}" maxlength="26" placeholder="Имя">
					<input type="text" v-model="surname" :class="{noempty: surname != ''}" maxlength="26" placeholder="Фамилия">
					<textarea v-model="position" :class="{noempty: position != ''}" maxlength="50" placeholder="Должность"></textarea>
					<input type="text" v-model="email" :class="{noempty: email != ''}" maxlength="26" placeholder="Email">
					<textarea v-model="department" :class="{noempty: department != ''}" class="full" maxlength="50" placeholder="Департамент или отдел"></textarea>
				</div>
			</div>

			<div class="logosContainer">
				<p class="logosContainer--title">Ваша компания</p>
				<ul class="logos">
					<li v-for="(item, index) in items" :key="index" class="logos__item" :class="item.class" @click="set_active_id(item.id)">
						<img :src="item.src" :class="[item.imgClass, item.addClass]" @load="reDraw" :title="item.title"/>
					</li>
				</ul>
			</div>

			<div class="cropper__buttons">
				<div class="cropper__buttons__left">
					<a class="clear" target="_blank" href="https://docs.google.com/forms/d/1dTPhVDJofKLJeKfERQle9VCB-rqpeIdo-UvWBH0A494/viewform?edit_requested=true"><span class="icon-feedback"></span>Обратная связь</a>
				</div>
				<div class="cropper__buttons__right">
					<a class="clear" @click="croppa.remove(); reDraw()"><span class="icon-trash"></span>Очистить</a>
					<a class="download" v-if="croppa.img && !loader" @click="download"><span class="icon-download"></span>Скачать</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Home',
	data () {
		return {
			croppa: {},
			dataUrl: '',
			loader: false,
			active: false,
			activeImage: false,
			items: [
				{id: 1, src: '1.svg', title: "Delko", class: '', imgClass: '', addClass: "smallInMobile"},
				{id: 2, src: '2.svg', title: "Альфа", class: '', imgClass: ''},
				{id: 3, src: '3.svg', title: "Альфа скан", class: '', imgClass: ''},
				{id: 4, src: '4.svg', title: "M7 truck", class: '', imgClass: ''},
				{id: 5, src: '5.svg', title: "Первый кузовной", class: '', imgClass: ''},
				{id: 6, src: '6.svg', title: "Мавинта", class: '', imgClass: ''},
				{id: 7, src: '7.png', title: "M7 Лизинг", class: '', imgClass: ''},
				{id: 8, src: '8.svg', title: "Альфа трейлер", class: '', imgClass: '', addClass: "smallInMobile"},
				{id: 9, src: '9.svg', title: "Азбука шин", class: '', imgClass: '', addClass: "smallInMobile"},
				{id: 10, src: '10.svg', title: "Альфа сервис", class: '', imgClass: ''},
				{id: 11, src: '11.svg', title: "Compas auto", class: '', imgClass: ''},
				{id: 12, src: '12.svg', title: "Мега разбор", class: '', imgClass: ''},
				{id: 13, src: '13.svg', title: "HR Delko", class: '', imgClass: ''},
				{id: 14, src: '14.svg', title: "HR Альфа", class: '', imgClass: ''},
				{id: 15, src: '15.svg', title: "Alfa driver", class: '', imgClass: ''},
				{id: 16, src: '16.svg', title: "IT 42", class: '', imgClass: ''},
			],
			previous_active_id: "",
			department: "",
			name: "",
			surname: "",
			position: "",
			email: "",
			departmentSplit: [],
			positionSplit: []
		}
	},
	beforeMount: function(){
		var comp = this;
		this.items.forEach(function(item, key){
			var image = require(`@/assets/img/logos/${item.src}`);
			comp.items[key].src = image;
		});
	},
	mounted: function(){
		this.reDraw();
		this.scaleInputes();

		window.addEventListener("resize", this.scaleInputes);
	},
	watch: {
		department: function(){
			var chunks = this.department.split(/\r*\n/g);
			var lastLineCutedTxt = '';

			for(var i = 0; i < chunks.length; i++)
			{
				chunks[i] = lastLineCutedTxt + chunks[i];

				if(chunks[i].length >= 25)
				{
					lastLineCutedTxt = chunks[i].slice(25);
					chunks[i] = chunks[i].slice(0, 25);
				}
				else lastLineCutedTxt = '';
			}

			if(lastLineCutedTxt != '')
				chunks.push(lastLineCutedTxt);

			this.department = chunks.slice(0, 2).join('\n');
			this.reDraw();
		},
		name: function(){
			this.reDraw();
		},
		surname: function(){
			this.reDraw();
		},
		position: function(){
			var chunks = this.position.split(/\r*\n/g);
			var lastLineCutedTxt = '';

			for(var i = 0; i < chunks.length; i++)
			{
				chunks[i] = lastLineCutedTxt + chunks[i];

				if(chunks[i].length >= 25)
				{
					lastLineCutedTxt = chunks[i].slice(25);
					chunks[i] = chunks[i].slice(0, 25);
				}
				else lastLineCutedTxt = '';
			}

			if(lastLineCutedTxt != '')
				chunks.push(lastLineCutedTxt);

			this.position = chunks.slice(0, 2).join('\n');

			this.reDraw();
		},
		email: function(){
			this.reDraw();
		}
	},
	methods: {
		clearInputes: function(){
			this.department = "";
			this.name = "";
			this.surname = "";
			this.email = "";
			this.position = "";

			var comp = this;

			if(this.previous_active_id){
				this.items.find(item => item.id === this.previous_active_id).class = ''
				this.items.find(item => item.id === this.previous_active_id).imgClass = ''
			}
			this.previous_active_id = ""

			if(this.croppa.img){
				this.croppa._draw()
			} else {
				setTimeout(function(){
					comp.reDraw();
				}, 1);
			}

		},
		uploadImageClick: function(){
			var input = this.$refs.croppa.$el.getElementsByTagName("input")[0];
			input.click()
		},
		scaleInputes: function(){
			var containerWidth = this.$refs.croppaContainer.offsetWidth;
			if(window.outerWidth > 1100){
				var scale = containerWidth / 800;
			} else{
				var scale = containerWidth / 269;
			}
			this.$refs.croppaInputes.style.transform = 'translate(-50%, -50%) scale(' + scale + ')';
		},
		rotateLeft: function(){
			var comp = this;
			comp.loader = true;
			setTimeout(function(){
				comp.croppa.rotate(-1);
				setTimeout(function(){
					comp.loader = false;
				}, 500);
			}, 1);
		},
		rotateRight: function(){
			var comp = this;
			comp.loader = true;
			setTimeout(function(){
				comp.croppa.rotate(1);
				setTimeout(function(){
					comp.loader = false;
				}, 500);
			}, 1);
		},
		reDraw: function(){
			var comp = this;

			if(this.croppa.img){
				this.croppa._draw()
			} else {
				comp.croppa.refresh()
				setTimeout(function(){
					var canvas = comp.$refs.croppa.$el.getElementsByTagName("canvas")[0];
					var ctx = canvas.getContext('2d');
					comp.onDraw(ctx)
				});
			}
		},
		startLoad:function(){
			this.loader = true;
		},
		endLoad:function(){
			var comp = this;
			if(this.croppa.img){
				comp.loader = false;
			}
		},
		onDraw: function (ctx, download = false) {
			var chunks = this.department.split(/\r*\n/g);
			this.departmentSplit = chunks;

			var chunks = this.position.split(/\r*\n/g);
			this.positionSplit = chunks;

			ctx.fillStyle = '#FFF';

			if(this.department != ""){
				var departamentTextRectHeight = 160;
				if(this.departmentSplit.length > 1){
					departamentTextRectHeight += 50 * (this.departmentSplit.length - 1);
				}

				this.roundRect(ctx, 0, 0, 1150, departamentTextRectHeight, {
					tr: 37,
					br: 37,
					bl: 37,
					tl: 75
				}, true, false);
			}


			if(this.name != "" || this.surname != "" || this.email != "" || this.position != ""){
				var dataTextRectHeight = 300;
				if(this.positionSplit.length > 1){
					dataTextRectHeight += 50 * (this.positionSplit.length - 1);
				}

				this.roundRect(ctx, 0, 2000 - dataTextRectHeight, 2000, dataTextRectHeight, {
					br: 75,
					bl: 75,
				}, true, false);
			}

			var activeImage = document.querySelector('.addon');
			if(activeImage){
				ctx.fillStyle = '#FFF';
				this.roundRect(ctx, 0, 1290, 1455, 292, {
					tr: 75,
					br: 75
				}, true, false);
				
				var imageHeight = 187;
				var scale = 187/(activeImage.height);
				var imageWidth = activeImage.width * scale;

				if(imageWidth > 800){
					var imageWidth = 800;
					var scale = 800/(activeImage.width);
					var imageHeight = activeImage.height * scale;
				}

				ctx.save()
				ctx.globalAlpha = 1
				ctx.drawImage(document.querySelector('.addon'), 888 - imageWidth / 2, 1438 - imageHeight / 2, imageWidth, imageHeight)
				this.active = true
				ctx.restore()

				this.activeImage = true;
			} else{
				this.activeImage = false;
			}

			var lineheight = 52;

			ctx.fillStyle = "#000";
			ctx.textAlign = "left";
			ctx.textBaseline = "top";

			ctx.font = "300 52px Montserrat";
			for (var i = 0; i < this.departmentSplit.length; i++){
				ctx.fillText(this.departmentSplit[i], 125, 50 + (i*lineheight));
			}

			lineheight = 47;
			ctx.font = "600 47px Montserrat";
			var nameTop = 1770;
			if(this.positionSplit.length > 1){
				nameTop -= (this.positionSplit.length - 1) * 27
			}			
			ctx.fillText(this.name, 145, nameTop);

			ctx.fillText(this.surname, 145, 1875);

			ctx.font = "300 47px Montserrat";
			var positionTop = 1770;
			if(this.positionSplit.length > 1){
				positionTop -= (this.positionSplit.length - 1) * 54
			}
			for (var i = 0; i < this.positionSplit.length; i++){
				ctx.fillText(this.positionSplit[i], 1080, positionTop + (i*lineheight));
			}

			ctx.fillText(this.email, 1080, 1875);

		},
		onFileSizeExceed () {
			alert('Файл слишком большой. Размер файла не более 10Мб.')
		},
		download(type, compressionRate) {
			var comp = this;
			this.croppa._draw(true);
			this.croppa.generateBlob((blob) => {
				var url = URL.createObjectURL(blob)
				var a = document.createElement('a');
				var name = 'Аватар';

				if(comp.name || comp.surname){
					name += " - ";
				}

				if(comp.name){
					name += comp.name;
				}
				if(comp.surname){
					if(comp.name){
						name += " ";
					}
					name += comp.surname;
				}
				if(comp.previous_active_id){
					var item = this.items.find(item => item.id === this.previous_active_id);
					name += " - " + item.title;
				}

				a.download = name +'.jpg';
				a.href = url;
				console.log(a);
				a.click();
				URL.revokeObjectURL(url);
			}, type, compressionRate)
		},
		set_active_id(id) {
			var comp = this;
			if (this.previous_active_id === id) return

			if(this.previous_active_id){
				this.items.find(item => item.id === this.previous_active_id).class = ''
				this.items.find(item => item.id === this.previous_active_id).imgClass = ''
			}
			this.items.find(item => item.id === id).class = 'active'
			this.items.find(item => item.id === id).imgClass = 'addon'

			if(this.croppa.img){
				this.croppa._draw()
			} else {
				setTimeout(function(){
					comp.reDraw();
				}, 1);
			}

			this.previous_active_id = id
		},
		roundRect(ctx, x, y, width, height, radius, fill, stroke) {
			if (typeof stroke === 'undefined') {
				stroke = true;
			}
			if (typeof radius === 'undefined') {
				radius = 5;
			}
			if (typeof radius === 'number') {
				radius = {tl: radius, tr: radius, br: radius, bl: radius};
			} else {
				var defaultRadius = {tl: 0, tr: 0, br: 0, bl: 0};
				for (var side in defaultRadius) {
					radius[side] = radius[side] || defaultRadius[side];
				}
			}
			ctx.beginPath();
			ctx.moveTo(x + radius.tl, y);
			ctx.lineTo(x + width - radius.tr, y);
			ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
			ctx.lineTo(x + width, y + height - radius.br);
			ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
			ctx.lineTo(x + radius.bl, y + height);
			ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
			ctx.lineTo(x, y + radius.tl);
			ctx.quadraticCurveTo(x, y, x + radius.tl, y);
			ctx.closePath();
			if (fill) {
				ctx.fill();
			}
			if (stroke) {
				ctx.stroke();
			}
		}
	},
}
</script>

<style lang="scss">
	.lds-roller {
		display: inline-block;
		width: 80px;
		height: 80px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		pointer-events: none;
	}
	.lds-roller div {
		animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		transform-origin: 40px 40px;
	}
	.lds-roller div:after {
		content: " ";
		display: block;
		position: absolute;
		width: 7px;
		height: 7px;
		border-radius: 50%;
		background: #EF6D00;
		margin: -4px 0 0 -4px;
	}
	.lds-roller div:nth-child(1) {
		animation-delay: -0.036s;
	}
	.lds-roller div:nth-child(1):after {
		top: 63px;
		left: 63px;
	}
	.lds-roller div:nth-child(2) {
		animation-delay: -0.072s;
	}
	.lds-roller div:nth-child(2):after {
		top: 68px;
		left: 56px;
	}
	.lds-roller div:nth-child(3) {
		animation-delay: -0.108s;
	}
	.lds-roller div:nth-child(3):after {
		top: 71px;
		left: 48px;
	}
	.lds-roller div:nth-child(4) {
		animation-delay: -0.144s;
	}
	.lds-roller div:nth-child(4):after {
		top: 72px;
		left: 40px;
	}
	.lds-roller div:nth-child(5) {
		animation-delay: -0.18s;
	}
	.lds-roller div:nth-child(5):after {
		top: 71px;
		left: 32px;
	}
	.lds-roller div:nth-child(6) {
		animation-delay: -0.216s;
	}
	.lds-roller div:nth-child(6):after {
		top: 68px;
		left: 24px;
	}
	.lds-roller div:nth-child(7) {
		animation-delay: -0.252s;
	}
	.lds-roller div:nth-child(7):after {
		top: 63px;
		left: 17px;
	}
	.lds-roller div:nth-child(8) {
		animation-delay: -0.288s;
	}
	.lds-roller div:nth-child(8):after {
		top: 56px;
		left: 12px;
	}
	@keyframes lds-roller {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}


	.preloader{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: calc(min(1.2vw, 30px));
		z-index: 2;
		img{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 20%;
		}
	}
	.showMobile{
		display: none;
	}
	.rotateBlock{
		position: absolute;
		top: 0;
		right: 0;
		height: calc(min(3.34vw, 64px));
		width: calc(min(8.4vw, 161px));
		font-size: calc(min(32px, 1.67vw));
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #fff;
		border-top-left-radius: 15px;
		border-bottom-right-radius: 15px;
		border-bottom-left-radius: 15px;
		&>a{
			margin-right: 21px;
			cursor: pointer;
			color: #A2A2A2;
			&:last-child{
				margin-right: 0;
			}
		}
	}
	.fakeBlock1{
		position: absolute;
		top: 2px;
		left: 2px;
		width: 458px;
		height: 66px;
		background-color: #fff;
		border-top-right-radius: 15px;
		border-bottom-right-radius: 15px;
		border-bottom-left-radius: 15px;
	}
	.fakeBlock2{
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 121px;
		background-color: #fff;
	}
	.uploadButton{
		position: absolute;
		top: 40.8%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		pointer-events: none;
		&--button{
			width: 129px;
			height: 129px;
			display: block;
			border-radius: 100%;
			background: #F2F2F2;
			border: 2px dashed #EF6D00;
			position: relative;
			display: block;
			margin: 0 auto;
			cursor: pointer;
			span {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				font-size: 70px;
				color: #EF6D00;
			}
		}
		&--text{
			margin-top: 11px;
			display: block;
			font-weight: bold;
			font-size: 29px;
			line-height: 41px;
			font-family: "Nunito", sans-serif;
			cursor: pointer;
		}
	}

	.inputes{
		input{
			position: absolute;
			font-size: 19px;
			line-height: 19px;
			background-color: #F8F8F8;
			border: none;
			padding: 3px 10px;
			min-height: 34px;
			border: 1px solid #EF6D00;
			border-radius: 4px;
			transition: all 0.3s ease;
			pointer-events: auto;
			&.noempty{
				border-color: #F8F8F8;
			}
		}
		textarea{
			position: absolute;
			font-size: 19px;
			line-height: 19px;
			background-color: #F8F8F8;
			border: none;
			padding: 5px 10px;
			min-height: 34px;
			border: 1px solid #EF6D00;
			border-radius: 4px;
			transition: all 0.3s ease;
			pointer-events: auto;
			&.noempty{
				border-color: #F8F8F8;
			}
		}
	}

	.croppaInputes{
		width: 799.5px;
		height: 799.5px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		pointer-events: none;
		&--department{
			top: 13px;
			left: 36px;
			width: 390px;
			font-size: 21px !important;
			line-height: 21px !important;
		}
		&--name{
			top: 700px;
			left: 50px;
			width: 325px;
		}
		&--surname{
			top: 742px;
			left: 50px;
			width: 325px;
		}
		&--position{
			top: 700px;
			left: 425px;
			width: 325px;
		}
		&--email{
			top: 742px;
			left: 425px;
			width: 325px;
		}
	}
	.cropper{
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		&__left{
			width: calc(50% - 43px);
			position: relative;
			display: block;
			background-color: #E2E2E2;
			border: 2px solid #d8d8d8;
			border-radius: calc(min(1.2vw, 30px));
			overflow: hidden;
			&:after{
				content: '';
				display: block;
				padding-top: 100%;
			}
		}
		&__right{
			width: calc(50% - 57px);
		}
		&__buttons{
			display: flex;
			align-items: center;
			justify-content: space-between;
			a{
				font-size: 25px;
				line-height: 32px;
				font-family: "Nunito", sans-serif;
				font-weight: 700;
				display: inline-flex;
				align-items: center;
				cursor: pointer;
				margin-right: 50px;
				&:last-child{
					margin-right: 0;
				}
				span{
					font-size: 25px;
					margin-right: 10px;
				}
			}
		}
	}

	.download{
		color: #EF6D00;
	}

	.croppa-container{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		cursor: pointer;
		canvas{
			width: 100% !important;
			height: 100% !important;
		}
	}

	.logosContainer{
		border: 2px solid #d8d8d8;
		padding: 74px 54px 110px;
		border-radius: calc(min(1.5vw, 30px));
		margin-bottom: 26px;
		&--title{
			font-size: 25px;
			line-height: 31px;
			font-weight: bold;
			margin-bottom: 54px;
			text-decoration: underline;
		}
	}

	.logos{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin: 0 -31px -79px;
		&__item{
			width: 25%;
			padding: 0 31px;
			margin-bottom: 79px;
			text-align: center;
			cursor: pointer;
			transition: opacity .3s ease;
			display: inline-flex;
			justify-content: center;
			&:hover{
				opacity: 0.6;
			}
			img{
				max-height: 60px;
			}
		}
	}

	@media screen and (max-width: 1550px){
		.logosContainer{
			padding: 60px 54px;
		}

		.logos{
			margin: 0 -15px -60px;
			&__item{
				width: 25%;
				padding: 0 15px;
				margin-bottom: 60px;
				text-align: center;
				cursor: pointer;
				transition: opacity .3s ease;
				&:hover{
					opacity: 0.6;
				}
			}
		}
	}

	@media screen and (max-width: 1400px){
		.cropper{
			&__left{
				width: calc(50% - 25px);
			}
			&__right{
				width: calc(50% - 25px);
			}
		}
	}

	@media screen and (max-width: 1350px){
		.cropper{
			&__buttons{
				a{
					font-size: 20px;
					line-height: 28px;
					margin-right: 20px;
					span{
						font-size: 20px;
						margin-right: 10px;
					}
				}
			}
		}
	}

	@media screen and (max-width: 1100px){
		.rotateBlock{
			height: 9vw;
			width: 20vw;
			font-size: 5vw;
			border-top-left-radius: 15px;
			border-bottom-right-radius: 15px;
			border-bottom-left-radius: 15px;
			&>a{
				margin-right: 10px;
			}
		}
		.showMobile{
			display: block;
		}
		.cropper{
			flex-wrap: wrap;
			&__left{
				width: 100%;
				border-radius: calc(min(3vw, 30px));
				margin-bottom: 15px;
			}
			&__right{
				width: 100%;
			}
			&__buttons{
				&__left{
					margin-top: 19px;
				}
				&__right{
					position: fixed;
					bottom: 0;
					left: 0;
					width: 100%;
					background-color: #fff;
					box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.25);
					padding: 20px 25px;
					display: flex;
					justify-content: space-between;
					&>a{
						margin-right: 0;
					}
				}
				a{
					font-size: 14px;
					line-height: 19px;
					span{
						font-size: 14px;
						margin-right: 10px;
					}
				}
			}
		}

		.inputesContainer{
			border: 2px solid #d8d8d8;
			padding: 12px 21px 16px;
			border-radius: calc(min(3vw, 30px));
			margin-bottom: 16px;
			&--title{
				font-weight: bold;
				font-size: 8px;
				line-height: 10px;
				text-decoration: none;
				margin-bottom: 6px;
			}
		}

		.logosContainer{
			padding: 15px 21px 16px;
			border-radius: calc(min(3vw, 30px));
			margin-bottom: 0;
			&--title{
				font-size: 9px;
				line-height: 10px;
				text-decoration: none;
				margin-bottom: 26px;
			}
		}

		.logos{
			margin: 0 -10px -42px;
			&__item{
				width: 33.33%;
				padding: 0 10px;
				margin-bottom: 42px;
				text-align: center;
				cursor: pointer;
				transition: opacity .3s ease;
				&:hover{
					opacity: 0.6;
				}
			}
		}

		.hideMobile{
			display: none;
		}

		.croppaInputes{
			width: 269px;
			height: 269px;
		}

		.uploadButton{
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			&--button{
				width: 60px;
				height: 60px;
				span {
					font-size: 32px;
					color: #EF6D00;
				}
			}
			&--text{
				margin-top: 5px;
				font-size: 14px;
				line-height: 20px;
			}
			&.noCenter{
				top: 38%;
			}
		}
		.inputes{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			margin-bottom: -7px;
			input{
				position: relative;
				font-size: 10px !important;
				line-height: 12px !important;
				padding: 5px 7px;
				border-radius: 5px;
				transition: all 0.3s ease;
				width: calc(50% - 5px);
				margin-bottom: 6px;
				&.full{
					width: 100%;
				}
			}
			textarea{
				position: relative;
				font-size: 10px !important;
				line-height: 12px !important;
				padding: 5px 7px;
				border-radius: 5px;
				transition: all 0.3s ease;
				width: calc(50% - 5px);
				margin-bottom: 6px;
				&.full{
					width: 100%;
				}
			}
		}
	}

	@media screen and (max-width: 550px){
		.logos__item img{
			max-height: 40px;
		}
	}

	@media screen and (max-width: 450px){
		.logos__item img{
			max-height: 30px;
			&.smallInMobile{
				max-height: 20px;
			}
		}
	}
</style>